<template>
  <div
    v-if="
      getUser.role.slug === 'rh' ||
        getUser.role.slug === 'super-administrateur'
    "
    no-body
  >
    <b-card>
      <b-card-header class="pb-50">
        <h5>Filtrages</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="10"
            lg="10"
            class="mb-md-0 mb-2"
          >
            <b-row>
              <b-col
                cols="12"
                sm="8"
                md="8"
                lg="8"
              >
                <label for="service">Services</label>
                <v-select
                  v-model="payloadFilter.service_id"
                  :options="serviceOptions"
                  class="invoice-filter-select"
                  label="name"
                  :reduce="(service) => service.id"
                >
                  <template v-slot:no-options>
                    <b-spinner
                      v-if="isServicesWithoutPaginationLoading"
                      style="width: 2.5rem; height: 2.5rem"
                      class="align-middle text-info"
                    />
                    <span
                      v-else
                      class="mt-1 font-medium-1"
                    >Aucun service ne correspond</span>
                  </template>
                </v-select>
              </b-col>

              <b-col
                cols="12"
                sm="6"
                md="2"
                lg="2"
                class="mt-2"
              >
                <b-button
                  variant="primary"
                  @click="applyFilterServicesAction()"
                >
                  <div v-if="isReLoadFilterData">
                    <span> Chargement ... </span>
                    <b-spinner small />
                  </div>

                  <span
                    v-else
                    class="text-nowrap font-medium-1"
                  >Filtrer</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              v-b-modal.modal-service
              variant="primary"
            >
              <span class="text-nowrap">Ajouter</span>
            </b-button>
            <b-modal
              id="modal-service"
              cancel-variant="outline-secondary"
              hide-footer
              centered
              no-close-on-backdrop
              no-close-on-esc
              title="Ajout d'un service"
            >
              <validation-observer
                #default="{}"
                ref="addServiceForm"
              >
                <!-- Form -->
                <b-form
                  class="p-2"
                  autocomplete="off"
                  @reset.prevent="hideModal"
                  @submit.prevent="applyAddServiceAction"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="designation"
                    rules="required"
                  >
                    <b-form-group
                      label="Désignation"
                      label-for="designation"
                    >
                      <b-form-input
                        id="designation"
                        v-model="payload.name"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        trim
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <b-form-group
                    label="Image du service"
                    label-for="name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="image"
                      rules="required"
                    >
                      <b-form-file
                        id="wildcard"
                        v-model="payload.image"
                        accept="image/*"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Form Actions -->
                  <div class="d-flex mt-2">
                    <b-button
                      variant="primary"
                      class="mr-2"
                      type="submit"
                    >
                      <div v-if="isAddServiceLoading">
                        <span> Chargement ... </span>
                        <b-spinner small />
                      </div>
                      <span v-else>Enregistrer</span>
                    </b-button>
                    <b-button
                      type="reset"
                      variant="outline-secondary"
                    >
                      Annuler
                    </b-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-modal>
            <b-modal
              id="update-modal-service"
              cancel-variant="outline-secondary"
              hide-footer
              centered
              no-close-on-backdrop
              no-close-on-esc
              title="Modification d'un service"
            >
              <validation-observer
                #default="{}"
                ref="updateServiceForm"
              >
                <!-- Form -->
                <b-form
                  class="p-2"
                  autocomplete="off"
                  @reset.prevent="hideUpdateServiceModal"
                  @submit.prevent="applyUpdateServiceAction"
                >
                  <b-media class="mb-2">
                    <template #aside>
                      <b-avatar
                        ref="previewEl"
                        :src="serviceImage"
                        size="100px"
                        rounded
                      />
                    </template>

                    <div class="d-flex flex-wrap">
                      <b-button
                        variant="primary"
                        @click="$refs.refInputEl.$el.click()"
                      >
                        <b-form-file
                          ref="refInputEl"
                          v-model="payload.image"
                          accept=".jpg, .png, .gif, .jpeg"
                          :hidden="true"
                          plain
                          @change="displayImage"
                        />
                        <span>Changer l'image</span>
                      </b-button>
                    </div>
                  </b-media>
                  <validation-provider
                    #default="{ errors }"
                    name="designation"
                    rules="required"
                  >
                    <b-form-group
                      label="Désignation"
                      label-for="designation"
                    >
                      <b-form-input
                        id="designation"
                        v-model="payload.name"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        trim
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <!-- Form Actions -->
                  <div class="d-flex mt-2">
                    <b-button
                      variant="primary"
                      class="mr-2"
                      type="submit"
                    >
                      <div v-if="isUpdateServiceLoading">
                        <span> Chargement ... </span>
                        <b-spinner small />
                      </div>
                      <span v-else>Modifier</span>
                    </b-button>
                    <b-button
                      type="reset"
                      variant="outline-secondary"
                    >
                      Annuler
                    </b-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-modal>
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            <b-button
              variant="primary"
              @click="reLoadDataServicesAction()"
            >
              <div v-if="isReLoadDataServices">
                <span> Chargement ... </span>
                <b-spinner small />
              </div>
              <span
                v-else
                class="text-nowrap font-medium-1"
              >Actualiser</span>
            </b-button>
          </b-col>
        </b-row>
      </div>

      <vue-good-table
        class="m-2"
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :is-loading="isServicesLoading"
      >
        <template slot="emptystate">
          <div class="text-center">
            <span> {{ isServicesLoading ? "" : "Liste vide" }} </span>
          </div>
        </template>
        <template slot="loadingContent">
          <div class="text-center text-info">
            <b-spinner class="align-middle" />
          </div>
        </template>

        <template
          slot="table-row"
          slot-scope="props"
        >
          <div
            v-if="props.column.field == 'image'"
            class="align-middle text-center"
          >
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  rounded
                  size="64"
                  :src="props.row.image"
                >
                  <feather-icon
                    v-if="props.row.image == ''"
                    size="24"
                    icon="ShoppingBagIcon"
                  />
                </b-avatar>
              </template>
            </b-media>
          </div>

          <div
            v-else-if="props.column.field == 'name'"
            class="align-middle text-center"
          >
            <span class="text-info font-small-5">
              {{ props.row.name }}
            </span>
          </div>

          <div
            v-else-if="props.column.field == 'professionals_count'"
            class="align-middle text-center"
          >
            <span
              class="font-small-5"
              :class="[
                props.row.professionals_count > 0 ? 'text-info' : 'text-danger',
              ]"
            >
              {{ props.row.professionals_count }}
            </span>
          </div>

          <div
            v-else-if="props.column.field == 'action'"
            class="align-middle text-center"
          >
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="25"
                    class="text-info align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  class="itemActions cursor-pointer"
                  @click="showUpdateServiceModal(props.row)"
                >
                  <feather-icon
                    icon="FileTextIcon"
                    class="text-info mr-1"
                    size="25"
                  />
                  <span>Modifier</span>
                </b-dropdown-item>
                <b-dropdown-item
                  class="itemActions cursor-pointer"
                  @click="applyArchiveServiceAction(props.row.id)"
                >
                  <feather-icon
                    variant="danger"
                    icon="ArchiveIcon"
                    class="text-warning mr-1"
                    size="25"
                  />
                  <span>Archiver</span>
                </b-dropdown-item>
                <b-dropdown-item
                  class="itemActions cursor-pointer"
                  @click="applyDeleteServiceAction(props.row.id)"
                >
                  <feather-icon
                    variant="danger"
                    icon="TrashIcon"
                    class="text-danger mr-1"
                    size="25"
                  />
                  <span>Supprimer</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </div>
        </template>
      </vue-good-table>

      <div class="d-flex justify-content-between m-2 flex-wrap">
        <b-button
          :disabled="metaData.prev_page_url == null"
          variant="outline-secondary"
          @click="applyLoadMoreServicesAction(metaData.prev_page_url)"
        >
          <span
            class="text-white text-nowrap font-medium-2"
          >Page précédente</span>
        </b-button>

        <div>
          <span
            class="text-white font-medium-2"
          >{{ metaData.current_page }} sur {{ metaData.last_page }} pages</span>
        </div>

        <div>
          <span
            class="text-white font-medium-2"
          >Total :{{ total }}</span>
        </div>

        <b-button
          :disabled="metaData.next_page_url == null"
          variant="outline-secondary"
          @click="applyLoadMoreServicesAction(metaData.next_page_url)"
        >
          <span class="text-nowrap font-medium-1 text-white">Page suivante</span>
        </b-button>
      </div>
    </b-card>

  </div>
  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import {
  // BTab,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  // BLink,
  // BFormSelect,
  // BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  // BTooltip,
  BFormGroup,
  BForm,
  BSpinner,
  BFormFile,
  // BTabs,
  // BAlert,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'

export default {
  components: {
    // BTab,
    BFormFile,
    // BFormSelect,
    ValidationProvider,
    ValidationObserver,
    // BTabs,
    BCard,
    // BAlert,
    BFormGroup,
    // BLink,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    // BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BForm,
    // BTooltip,
    BSpinner,
    vSelect,
    VueGoodTable,
  },

  data() {
    return {
      total: '',

      payloadFilter: {
        service_id: '',
      },

      serviceOptions: [],

      isReLoadFilterData: false,

      dir: false,
      tableItems: [],
      required,
      alphaNum,
      isAddServiceLoading: false,
      isUpdateServiceLoading: false,
      isReLoadDataServices: false,
      serviceId: null,
      serviceImage: null,
      payload: {
        name: '',
        image: null,
      },
      email,

      rows: [],
      columns: [
        {
          label: 'Image',
          field: 'image',
        },
        {
          label: 'Nom',
          field: 'name',
          // filterOptions: {
          //   enabled: true,
          //   placeholder: 'Rechercher...',
          //   filterFn(data, filterString) {
          //     return data.toLowerCase().includes(filterString.toLowerCase())
          //   },
          // },
        },
        {
          label: 'Nombre de Pros',
          field: 'professionals_count',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],

      statusFilter: null,
      avatarText,
    }
  },

  computed: {
    ...mapGetters('services', ['getServices', 'getServicesWithoutPagination']),
    ...mapGetters(['isServicesLoading', 'isServicesWithoutPaginationLoading']),
    ...mapGetters('auth', ['getUser']),

    metaData() {
      const meta = {
        prev_page_url: '',
        next_page_url: '',
        current_page: '',
        last_page: '',
        current_page_url: '',
      }
      if (this.getServices != null) {
        meta.prev_page_url = this.getServices.prev_page_url
        meta.next_page_url = this.getServices.next_page_url
        meta.current_page = this.getServices.current_page
        meta.last_page = this.getServices.last_page
        meta.current_page_url = `${this.getServices.path}?page=${this.getServices.current_page}`
        this.total = this.getServices.total
      }
      return meta
    },

    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },

  watch: {
    payloadFilter: {
      handler(newValue, oldValue) {
        const keys = Object.keys(newValue)
        keys.forEach(key => {
          if (newValue[key] == null) {
            newValue[key] = ''
            console.log(this.payloadFilter)
          }
        })
      },
      deep: true,
    },

    isServicesWithoutPaginationLoading(val) {
      if (val === false) {
        this.getServicesWithoutPagination.forEach(element => {
          this.serviceOptions.push({
            id: element.id,
            name: element.name,
          })
        })
      }
    },

    isServicesLoading(val) {
      if (val === false) {
        this.rows = this.getServices.data
      }
    },
  },

  created() {
    this.getServices.data != null ? (this.rows = this.getServices.data) : null
    if (this.isServicesLoading === false) {
      this.rows = this.getServices.data
    }
  },

  mounted() {
    if (this.getServicesWithoutPagination.length != 0) {
      this.getServicesWithoutPagination.forEach(element => {
        this.serviceOptions.push({
          id: element.id,
          name: element.name,
        })
      })
    }
  },

  methods: {
    ...mapActions('services', [
      'addServiceAction',
      'deleteServiceAction',
      'getServicesAction',
      'updateServiceAction',
      'archiveServiceAction',
      'loadMoreServicesAction',
      'filterServicesAction',
    ]),

    applyFilterServicesAction() {
      const allIsNUl = Object.values(this.payloadFilter).every(
        el => el === '',
      )
      if (allIsNUl) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Warning',
              icon: 'CheckIcon',
              text: 'Veuillez sélectionner au moins un champ du filtre.',
              variant: 'warning',
            },
          },
          {
            position: 'top-center',
          },
        )
      } else {
        this.isReLoadFilterData = true
        this.rows = []
        this.filterServicesAction(this.payloadFilter)
          .then(response => {
            this.isReLoadFilterData = false
            this.rows = this.getServices
            this.total = this.rows.length
          })
          .catch(error => {
            console.log(error)
            this.isReLoadFilterData = false
          })
      }
    },

    applyLoadMoreServicesAction(url) {
      this.$store.commit('SET_IS_SERVICES_LOADING', true)
      this.rows = []
      this.loadMoreServicesAction(url)
        .then(() => {
          this.$store.commit('SET_IS_SERVICES_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_SERVICES_LOADING', false)
        })
    },

    reLoadDataServicesAction() {
      this.payloadFilter.service_id = ''
      this.isReLoadDataServices = true
      this.$store.commit('SET_IS_SERVICES_LOADING', true)
      this.rows = []
      this.getServicesAction()
        .then(response => {
          this.isReLoadDataServices = false
          this.$store.commit('SET_IS_SERVICES_LOADING', false)
        })
        .catch(error => {
          this.isReLoadDataServices = false
          this.$store.commit('SET_IS_SERVICES_LOADING', false)
        })
    },

    displayImage() {
      // eslint-disable-next-line prefer-destructuring
      this.payload.image = this.$refs.refInputEl.$el.files[0]
      this.serviceImage = URL.createObjectURL(this.payload.image)
    },

    resolveProStatusVariant: status => {
      if (status === true) return 'success'
      return 'warning'
    },

    resolveProStatusText: status => {
      if (status === true) return 'Validé'
      return 'En attente de validation'
    },

    showUpdateServiceModal(service) {
      this.$bvModal.show('update-modal-service')
      this.serviceId = service.id
      this.serviceImage = service.image
      this.payload.name = service.name
    },

    applyAddServiceAction() {
      this.$refs.addServiceForm.validate().then(success => {
        if (success) {
          // eslint-disable-next-line no-unused-expressions
          this.isAddServiceLoading = true
          this.addServiceAction(this.payload)
            .then(response => {
              this.getServicesAction().then(() => {
                this.isAddServiceLoading = false
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Succès',
                      icon: 'CheckIcon',
                      text: response.message,
                      variant: 'success',
                    },
                  },
                  {
                    position: 'top-center',
                  },
                )

                this.$bvModal.hide('modal-service')
                this.payload = {
                  name: '',
                  image: '',
                }
                this.applyLoadMoreServicesAction(
                  this.metaData.current_page_url,
                )
              })
            })
            .catch(error => {
              console.log(error)
              this.isAddServiceLoading = false
            })
        }
      })
    },

    applyUpdateServiceAction() {
      this.$refs.updateServiceForm.validate().then(success => {
        if (success) {
          this.isUpdateServiceLoading = true
          if (this.payload.image == null) {
            delete this.payload.image
          }
          this.updateServiceAction({
            payload: this.payload,
            id: this.serviceId,
          })
            .then(response => {
              this.getServicesAction().then(() => {
                this.isUpdateServiceLoading = false
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Succès',
                      icon: 'CheckIcon',
                      text: response.message,
                      variant: 'success',
                    },
                  },
                  {
                    position: 'top-center',
                  },
                )

                this.$bvModal.hide('update-modal-service')
                this.payload = {
                  name: '',
                  image: '',
                }
                this.applyLoadMoreServicesAction(
                  this.metaData.current_page_url,
                )
              })
            })
            .catch(error => {
              console.log(error)
              this.isUpdateServiceLoading = false
            })
        }
      })
    },

    applyDeleteServiceAction(id) {
      this.$swal({
        title: 'Suppression',
        text: 'Êtes-vous sûr de vouloir supprimer ce service?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        preConfirm: async () => {
          await this.deleteServiceAction(id)
          // eslint-disable-next-line no-return-await
          return await this.getServicesAction()
        },
      })
        .then(response => {
          if (response.value) {
            this.applyLoadMoreServicesAction(this.metaData.current_page_url)
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Service supprimé avec succès',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applyArchiveServiceAction(id) {
      this.$swal({
        title: 'Archivage',
        text: 'Êtes-vous sûr de vouloir archiver ce service?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        preConfirm: async () => {
          await this.archiveServiceAction(id)
          return await this.getServicesAction()
        },
      })
        .then(response => {
          console.log(response)
          if (response.value) {
            this.applyLoadMoreServicesAction(this.metaData.current_page_url)
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Service archivé avec succès',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    hideModal() {
      this.$bvModal.hide('modal-service')
      this.payload = {
        name: '',
        image: '',
      }
    },

    hideUpdateServiceModal() {
      this.$bvModal.hide('update-modal-service')
      this.payload = {
        name: '',
        image: '',
      }
    },
  },
}
</script>

<style>
i.icon-code {
  display: none !important;
}

.vgt-select {
  background: transparent !important;
  border-color: #404656 !important;
}

.itemActions :hover {
  background: transparent !important;
}
</style>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
